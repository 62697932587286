<section class="page-title-area item-bg3">
    <div class="container">
        <h2>{{ 'BLOG.TITLE-6' | translate }}</h2>
    </div>
</section>

<section class="blog-details-area ps-3 py-3">
    <div class="container">
        <div class="row">

            <div class="blog-details about-content">
                <div class="post-image text-center">
                    <img src="assets/img/blog-matrimonio.jpg" alt="image">
                </div>
                <h3>{{ 'BLOG.TITLE-6' | translate }}</h3>
                <div class="blog-meta">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-clock-time"></i> Jul 4, 2023</li>
                    </ul>
                </div>




                <p>{{ 'BLOG.TEXT-6-1' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-6-2' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-4' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-5' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-6' | translate }}</p>



                <h3 class="py-3">{{ 'BLOG.TEXT-6-7' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-9' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-10' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-11' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-12' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-13' | translate }}</p>


                <h3 class="py-3">{{ 'BLOG.TEXT-6-14' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-15' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-16' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-17' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-6-18' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-19' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-20' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-21' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-22' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-6-23' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-24' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-25' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-26' | translate }}</p>
                <p>{{ 'BLOG.TEXT-6-27' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-6-28' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-6-29' | translate }}</p>

                <div class="py-5 text-center">
                    <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                </div>

            </div>
        </div>
    </div>
</section>
