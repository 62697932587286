<section class="page-title-area item-bg1">
    <div class="container">
        <h2>Blog Grid</h2>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog1.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">Technology</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">25 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">The most popular new top business apps</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog2.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">Agency</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">27 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">The best marketing top use management tools</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog3.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">IT</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">28 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog4.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">Creative</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">29 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog5.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">IT</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">28 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">The month in WordPress: January 2023</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog6.jpg" alt="image"></a>
                        <div class="post-tag">
                            <a routerLink="#">Creative</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">29 Feb, 2023</span>
                        <h3><a routerLink="/blog-details">A new way to earn money on WordPress.com</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-left"></i></a></li>
                            <li class="page-item"><a class="page-link active" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>