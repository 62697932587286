<section class="page-title-area item-bg3">
    <div class="container">
        <h2>{{ 'BLOG.DETAILS' | translate }} </h2>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">

                <div class="blog-details">
                    <div class="post-image">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </div>
                    <h3>The Most Popular New top Business Apps</h3>
                    <div class="blog-meta">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-clock-time"></i> June 20, 2023</li>
                        </ul>
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing. There are many that an variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by a injected humour or randomised words which don’t look even of slightly believable. If you are going to use a the passage of Lorem Ipsum you need to be sure there isn’t anything embarrassing.</p>
                    <blockquote class="blockquote">
                        <p>There are many variations of passages of Lorem the Ipsum available but the that as that majority have is suffered alteration.</p>
                    </blockquote>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>Randomised words which don’t look even slightly believable. If you are going a to use a passage you need to be sure there isn’t anything embarrassing. Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
        </div>
    </div>
</section>
