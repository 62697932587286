<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.png" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>{{ 'MENU.ABOUT' | translate }}</span>
                    <h2>{{ 'ABOUT.TITLE-1' | translate }} {{ 'ABOUT.TITLE-2' | translate }} </h2>
                    <p>{{ 'ABOUT.SUB-TITLE' | translate }}</p>
                    <ul class="p-2">
                        <li>{{ 'SECTION-2.ITEM-1' | translate }}</li>
                        <li>{{ 'SECTION-2.ITEM-2' | translate }}</li>
                        <li>{{ 'SECTION-2.ITEM-3' | translate }}</li>
                        <li>{{ 'SECTION-2.ITEM-4' | translate }}</li>
                    </ul>
                    <p>{{ 'SECTION-2.TEXT-2' | translate }}</p>
                    <div class="py-5">
                        <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


