<section class="page-title-area item-bg3">
    <div class="container">
        <h2>{{ 'BLOG.TITLE-1' | translate }} </h2>
    </div>
</section>

<section class="blog-details-area ps-3 py-3">
    <div class="container">
        <div class="row">

            <div class="blog-details about-content">
                <div class="post-image text-center">
                    <img src="assets/img/asilo.png" alt="image">
                </div>
                <h3>{{ 'BLOG.TITLE-1' | translate }}</h3>
                <div class="blog-meta">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-clock-time"></i> Mar 17, 2023</li>
                    </ul>
                </div>

                <p>{{ 'BLOG.TEXT-1-1' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-2' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-3' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-4' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-1-5' | translate }}</p>


                <p>{{ 'BLOG.TEXT-1-6' | translate }}</p>

                <ul class="pb-3">
                    <li>{{ 'BLOG.TEXT-1-7' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-8' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-9' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-10' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-11' | translate }} </li>

                </ul>

                <p>{{ 'BLOG.TEXT-1-12' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-13' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-1-14' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-15' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-16' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-17' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-1-18' | translate }}</p>


                <ul class="pb-3">
                    <li>{{ 'BLOG.TEXT-1-19' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-20' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-21' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-22' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-23' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-24' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-25' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-26' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-27' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-1-28' | translate }} </li>
                </ul>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-29' | translate }}</h3>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-30' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-1-31' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-32' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-1-33' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-34' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-1-35' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-36' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-1-37' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-1-38' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-1-39' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-40' | translate }}</p>
                <p>{{ 'BLOG.TEXT-1-41' | translate }}</p>

                <div class="py-5 text-center">
                    <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                </div>

            </div>
        </div>
    </div>
</section>
