import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/SharedService.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router, translate: TranslateService, private sharedService: SharedService) {

        let current_lan = navigator.language ? navigator.language : 'en';
        current_lan = current_lan.substring(0, 2);
        let lan = localStorage.getItem('dan-lan') ? localStorage.getItem('dan-lan') : current_lan;

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(lan);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(lan);

        localStorage.setItem('dan-lan', lan);

        sharedService.setVisibleMenu(true);
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
