<section class="page-title-area item-bg2">
    <div class="container">
        <h2>Blog Right Sidebar</h2>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog1.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">Technology</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">25 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">The most popular new top business apps</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog2.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">Agency</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">27 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">The best marketing top use management tools</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog3.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">IT</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">28 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog4.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">Creative</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">29 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog5.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">IT</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">28 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">The month in WordPress: January 2023</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog6.jpg" alt="image"></a>
                                <div class="post-tag">
                                    <a routerLink="#">Creative</a>
                                </div>
                            </div>
                            <div class="blog-post-content">
                                <span class="date">29 Feb, 2023</span>
                                <h3><a routerLink="/blog-details">A new way to earn money on WordPress.com</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <nav aria-label="Page navigation">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-left"></i></a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-right"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sidebar">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="form-control" placeholder="Search here...">
                            <button type="submit"><i class="icofont-search"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">
                            Categories
                        </h3>
                        <ul>
                            <li><a href="#">AJAX</a></li>
                            <li><a href="#">Apache</a></li>
                            <li><a href="#">CSS</a></li>
                            <li><a href="#">PHP</a></li>
                            <li><a href="#">Django</a></li>
                            <li><a href="#">Error</a></li>
                            <li><a href="#">IIS</a></li>
                            <li><a href="#">JavaScript</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">
                            Recent Posts
                        </h3>
                        <ul>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog1.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The most popular new top business apps</a></h5>
                                <p class="date">25 Feb, 2023</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog2.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The best marketing top use management tools</a></h5>
                                <p class="date">27 Feb, 2023</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog3.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h5>
                                <p class="date">28 Feb, 2023</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog4.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h5>
                                <p class="date">29 Feb, 2023</p>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">
                            Tags
                        </h3>
                        <div class="tagcloud">
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                        </div>
                    </div>
                    <div class="widget widget_archive">
                        <h3 class="widget-title">
                            Archives
                        </h3>
                        <ul>
                            <li><a href="#">December 2023</a></li>
                            <li><a href="#">January 2023</a></li>
                            <li><a href="#">February 2023</a></li>
                            <li><a href="#">March 2023</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>