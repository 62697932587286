<div class="main-banner clip-path-banner item-bg3 jarallax" data-jarallax='{"speed": 0.2}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content text-center">
                    <h1>
                        {{ 'HOME.TITLE-1' | translate }} {{ 'HOME.TITLE-2' | translate }} {{ 'HOME.TITLE-3' | translate }}
                    </h1>
                    <p>
                        {{ 'HOME.SUB-TITLE' | translate }}
                    </p>
                    <div class="btn-box">
                        <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1 d-none d-md-block"><img src="assets/img/shapes/1.png" alt="shape1"></div>


</div>


<app-section-1></app-section-1>


<app-about></app-about>

<app-services></app-services>

<app-team></app-team>

<app-testimonial></app-testimonial>

<app-blog></app-blog>

<!--<app-subscribe></app-subscribe>-->

<app-contact></app-contact>
