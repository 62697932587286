<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'MENU.TESTIMONIOS' | translate }}</h2>

        </div>

        <div class="testimonial-slides owl-carousel owl-theme">


            <div class="desc">
                <div class="blog-details">
                    <blockquote class="blockquote">
                        <p>{{ 'TESTIMONIOS.TEXT-TEST-2' | translate }}</p>
                    </blockquote>
                    <div class="client-info">
                        <h3>{{ 'TESTIMONIOS.TITLE-TEST-2' | translate }}</h3>
                    </div>
                </div>
            </div>

            <div class="desc">
                <div class="blog-details">
                    <blockquote class="blockquote">
                        <p class="p-2">{{ 'TESTIMONIOS.TEXT-TEST-3' | translate }}</p>
                    </blockquote>
                    <div class="client-info">
                        <h3>{{ 'TESTIMONIOS.TITLE-TEST-3' | translate }}</h3>
                    </div>
                </div>
            </div>

            <div class="desc">
                <div class="blog-details">
                    <blockquote class="blockquote">
                        <p >{{ 'TESTIMONIOS.TEXT-TEST-1' | translate }}</p>
                    </blockquote>
                    <div class="client-info">
                        <h3>{{ 'TESTIMONIOS.TITLE-TEST-1' | translate }}</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
