<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <p>© DANIEL ORTIZ - LAW OFFICES</p>

                <!--<p>© Made with <span class="red">&#10084;</span> by <a href="https://www.highcreativityconsulting.com/" target="_blank">HIGH</a></p>-->
            </div>
            <div class="col-lg-6 col-md-5">
                <ul>
                    <li><a href="https://www.facebook.com/DanOrtizLaw" target="_blank"><i class="icofont-facebook"></i></a></li>
                    <li><a href="https://x.com/DanOrtizLawyer" target="_blank"><i class="icofont-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/danortizlawoffices" target="_blank"><i class="icofont-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/daniel-ortiz-0136035/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->
