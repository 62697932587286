import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private visibleMenuSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    setVisibleMenu(data: boolean): void {
        this.visibleMenuSubject.next(data);
    }

    getVisibleMenu$(): Observable<boolean> {
        return this.visibleMenuSubject.asObservable();
    }
}
