<section class="features-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'SECTION-1.TITLE' | translate }}</h2>
            <p>{{ 'SECTION-1.SUB-TITLE' | translate }}</p>
        </div>

        <section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="single-funfact">
                            <img src="assets/img/cases.png" alt="cases" class="w-25">
                            <h3 class="odometer" data-count="3000">00</h3>
                            
                            <p class="sub-title pt-5">{{ 'SECTION-1.TEXT-1' | translate }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="single-funfact">
                            <img src="assets/img/years.png" alt="cases" class="w-25">
                            <h3 class="odometer" data-count="15">00</h3>
                            <p class="sub-title pt-5">{{ 'SECTION-1.TEXT-2' | translate }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="single-funfact">
                            <img src="assets/img/experts.png" alt="cases" class="w-25">
                            <h3 class="odometer" data-count="5000">00</h3>
                            <p class="sub-title pt-5">{{ 'SECTION-1.TEXT-3' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>





    </div>
</section>
