<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" style="margin-right: 0px !important" routerLink="/">
            <!--Mobile-->
            <div class='d-md-none'>
                <img src="assets/img/logo-movil.png" class="img-fluid logo-dan" alt="logo">
                <img src="assets/img/logo-movil2.png" class="img-fluid logo-dan" alt="logo">
            </div>

            <!--Desktop-->
            <div class='d-none d-md-block'>
                <img src="assets/img/logo.png" class="img-fluid" alt="logo">
                <img src="assets/img/logo2.png" class="img-fluid" alt="logo">
            </div>
        </a>

        <button  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!--Languaje Mobile-->
        <div class="flag px-2 d-md-none">
            <span *ngIf="currentLan == 'en'" (click)="lanChange('es')" class="fi fi-es link p-1"></span>
            <span *ngIf="currentLan == 'es'" (click)="lanChange('en')" class="fi fi-us link p-1"></span>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <ng-container *ngIf="menuVisible">
                    <li class="nav-item"><span class="nav-link" (click)="onClick('home')">{{ 'MENU.HOME' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('about')">{{ 'MENU.ABOUT' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('services')">{{ 'MENU.SERVICIOS' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('team')">{{ 'MENU.EQUIPO' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('testimonial')">{{ 'MENU.TESTIMONIOS' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">{{ 'MENU.BLOG' | translate }}</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">{{ 'MENU.CONTACTO' | translate }}</span></li>
                </ng-container>

                <li *ngIf="!menuVisible" routerLink="/" class="nav-item"><span class="nav-link">{{ 'MENU.ATRAS' | translate }}</span></li>

                <!--Languaje desktop-->
                <li class="flag px-2 d-none d-md-block">
                    <span *ngIf="currentLan == 'en'" (click)="lanChange('es')" class="fi fi-es link p-1"></span>
                    <span *ngIf="currentLan == 'es'" (click)="lanChange('en')" class="fi fi-us link p-1"></span>
                </li>

            </ul>
        </div>
    </div>
</nav>
