import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-1',
    templateUrl: './section-1.component.html',
    styleUrls: ['./section-1.component.scss']
})
export class Section1Component implements OnInit {

    constructor() { }

    ngOnInit() {
    }



}

