<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Trusted Client</span>
            <h2>Our Happy <b>Partners</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner1.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner2.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner3.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner4.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner5.png" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner6.png" alt="img">
                </a>
            </div>
        </div>
    </div>
</section>