<section class="why-we-different ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>Highly Creative Solutions</span>
            <h2>Why we are <b>Different</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    Speed , Flexibility
                </a></li>
                <li><a href="#">
                    Profeesional work
                </a></li>
                <li><a href="#">
                    Experienced staff
                </a></li>
                <li><a href="#">
                    Customer support
                </a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Speed , Flexibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different1.jpg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Profeesional Work</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Experience Staff</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different3.jpg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img src="assets/img/why-we-different4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>24/7 Support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li>Creative Design</li>
                                    <li>Retina Ready</li>
                                    <li>Modern Design</li>
                                    <li>Digital Marketing & Branding</li>
                                    <li>Awesome Design</li>
                                    <li>We Are Professional</li>
                                    <li>We Are Creative</li>
                                    <li>We Are Unique</li>
                                </ul>
                                <a href="#portfolio" class="btn btn-primary">Our Work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>