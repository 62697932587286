

    <section class="why-we-different ptb-100 bg-image">
        <div class="container">
            <div class="tab">
                <div class="tab_content">
                    <div class="tabs_item">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12 why-we-different-content">
                                <div class="tabs_item_content">
                                    <h3>{{ 'SECTION-2.TITLE' | translate }}</h3>
                                    <p>{{ 'SECTION-2.SUB-TITLE' | translate }}</p>
                                    <ul>
                                        <li>¿que ponemos aquí?</li>
                                        <li>¿que ponemos aquí?</li>
                                        <li>We Are Professional</li>
                                        <li>We Are Creative</li>
                                        <li>We Are Unique</li>
                                    </ul>
                                    <a href="#about" class="btn btn-primary">{{ 'SECTION-2.BTN' | translate }}</a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 why-we-different-img">
                                <div class="tabs_item_img">
                                    <img src="assets/img/about-dan.png" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>


