import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { StoryComponent } from './components/layouts/story/story.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { TeamComponent } from './components/layouts/team/team.component';
import { TestimonialComponent } from './components/layouts/testimonial/testimonial.component';
import { CtaComponent } from './components/layouts/cta/cta.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { CallToActionComponent } from './components/layouts/call-to-action/call-to-action.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { ContactComponent } from './components/layouts/contact/contact.component';
import { PartnerComponent } from './components/layouts/partner/partner.component';
import { AboutComponent } from './components/layouts/about/about.component';
import { ServicesComponent } from './components/layouts/services/services.component';
import { PortfolioComponent } from './components/layouts/portfolio/portfolio.component';
import { SkillsComponent } from './components/layouts/skills/skills.component';
import { FeaturesComponent } from './components/layouts/features/features.component';
import { WhyWeDifferentComponent } from './components/layouts/why-we-different/why-we-different.component';
import { FunfactsComponent } from './components/layouts/funfacts/funfacts.component';
import { PricingComponent } from './components/layouts/pricing/pricing.component';
import { FaqComponent } from './components/layouts/faq/faq.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { CtaTwoComponent } from './components/layouts/cta-two/cta-two.component';
import { WhoWeAreComponent } from './components/layouts/who-we-are/who-we-are.component';
import { FunfactsTwoComponent } from './components/layouts/funfacts-two/funfacts-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { HomeEightComponent } from './components/pages/home-eight/home-eight.component';
import { HomeNineComponent } from './components/pages/home-nine/home-nine.component';
import { HomeTenComponent } from './components/pages/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/pages/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/pages/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/pages/home-thirteen/home-thirteen.component';
import { HomeFourteenComponent } from './components/pages/home-fourteen/home-fourteen.component';
import { HomeFifteenComponent } from './components/pages/home-fifteen/home-fifteen.component';
import { HomeSixteenComponent } from './components/pages/home-sixteen/home-sixteen.component';
import { HomeSeventeenComponent } from './components/pages/home-seventeen/home-seventeen.component';
import { HomeEighteenComponent } from './components/pages/home-eighteen/home-eighteen.component';
import { HomeNineteenComponent } from './components/pages/home-nineteen/home-nineteen.component';
import { HomeTwentyComponent } from './components/pages/home-twenty/home-twenty.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { DemoSidebarComponent } from './components/layouts/demo-sidebar/demo-sidebar.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ReactiveFormsModule } from '@angular/forms';



//import { MatSelectModule } from '@angular/material/select';
//import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';



import { Section1Component } from './components/layouts/section-1/section-1.component';
import { Section2Component } from './components/layouts/section-2/section-2.component';
import { BlogAsiloComponent } from './components/layouts/blog-asilo/blog-asilo.component';
import { BlogParoleComponent } from './components/layouts/blog-parole/blog-parole.component';
import { BlogTPSComponent } from './components/layouts/blog-tps/blog-tps.component';
import { BlogHB1105Component } from './components/layouts/blog-HB-1105/blog-HB-1105.component';
import { BlogCBPOneComponent } from './components/layouts/blog-cbp-one/blog-cbp-one.component';
import { BlogMatrimonioResidenciaComponent } from './components/layouts/blog-matrimonio-residencia/blog-matrimonio-residencia.component';






// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        StoryComponent,
        HomeOneComponent,
        TeamComponent,
        TestimonialComponent,
        CtaComponent,
        HomeTwoComponent,
        CallToActionComponent,
        SubscribeComponent,
        ContactComponent,
        PartnerComponent,
        AboutComponent,
        ServicesComponent,
        PortfolioComponent,
        SkillsComponent,
        FeaturesComponent,
        WhyWeDifferentComponent,
        FunfactsComponent,
        PricingComponent,
        FaqComponent,
        BlogComponent,
        CtaTwoComponent,
        WhoWeAreComponent,
        FunfactsTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent,
        HomeThirteenComponent,
        HomeFourteenComponent,
        HomeFifteenComponent,
        HomeSixteenComponent,
        HomeSeventeenComponent,
        HomeEighteenComponent,
        HomeNineteenComponent,
        HomeTwentyComponent,
        BlogOneComponent,
        BlogTwoComponent,
        BlogDetailsComponent,
        ErrorComponent,
        DemoSidebarComponent,

        Section1Component,
        Section2Component,
        BlogAsiloComponent,
        BlogParoleComponent,
        BlogTPSComponent,
        BlogHB1105Component,
        BlogCBPOneComponent,
        BlogMatrimonioResidenciaComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgxScrollTopModule,
        ReactiveFormsModule,

        //MatSelectModule,

        //MatSnackBarModule,

        MatCheckboxModule,
        MatFormFieldModule,

        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })

    ],
    providers: [
    provideAnimationsAsync()
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
