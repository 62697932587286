<section class="skill-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-content ptb-100">
                    <div class="section-title">
                        <span>We Are Awesome</span>
                        <h2>Some Of Our <b>Skills</b></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="skills">
                        <div class="skill-item">
                            <div class="skill-header">
                                <h3 class="skill-title">Branding</h3>
                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="95">0</span>%</div>
                                </div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h3 class="skill-title">Marketing</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="90">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h3 class="skill-title">Design</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="95">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h3 class="skill-title">Development</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="93">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="93"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-image">
                    <img src="assets/img/skill-bg.jpg" alt="skill-image">
                </div>
            </div>
        </div>
    </div>
</section>