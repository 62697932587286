<section class="page-title-area item-bg3">
    <div class="container">
        <h2>{{ 'BLOG.TITLE-3' | translate }}</h2>
    </div>
</section>

<section class="blog-details-area ps-3 py-3">
    <div class="container">
        <div class="row">

            <div class="blog-details about-content">
                <div class="post-image text-center">
                    <img src="assets/img/tps.png" alt="image">
                </div>
                <h3>{{ 'BLOG.TITLE-3' | translate }}</h3>
                <div class="blog-meta">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-clock-time"></i> Mar 17, 2023</li>
                    </ul>
                </div>
                <p>{{ 'BLOG.TEXT-3-1' | translate }}</p>
                <p>{{ 'BLOG.TEXT-3-2' | translate }}</p>
                <p>{{ 'BLOG.TEXT-3-3' | translate }}</p>
                <p>{{ 'BLOG.TEXT-3-4' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-5' | translate }}</h3>

                <ul class="pb-3" style="column-count: 3">

                    <li>{{ 'BLOG.TEXT-3-6' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-7' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-8' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-9' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-10' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-11' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-12' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-13' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-14' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-15' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-16' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-17' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-18' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-19' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-20' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-30' | translate }} </li>
                </ul>


                <h3 class="py-3">{{ 'BLOG.TEXT-3-31' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-3-32' | translate }}</p>

                <ul class="pb-3">

                    <li>{{ 'BLOG.TEXT-3-33' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-34' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-35' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-36' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-37' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-38' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-39' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-40' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-41' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-42' | translate }} </li>
                </ul>



                <h3 class="py-3">{{ 'BLOG.TEXT-3-43' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-3-44' | translate }}</p>

                <ul class="pb-3">

                    <li>{{ 'BLOG.TEXT-3-45' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-46' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-47' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-48' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-49' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-50' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-51' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-3-52' | translate }} </li>
                </ul>


                <h3 class="py-3">{{ 'BLOG.TEXT-3-53' | translate }}</h3>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-54' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-55' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-56' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-57' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-58' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-59' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-60' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-61' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-62' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-63' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-3-64' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-3-65' | translate }}</p>
                <p>{{ 'BLOG.TEXT-3-66' | translate }}</p>


                <div class="py-5 text-center">
                    <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                </div>

            </div>
        </div>
    </div>
</section>
