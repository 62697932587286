import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-2',
    templateUrl: './section-2.component.html',
    styleUrls: ['./section-2.component.scss']
})
export class Section2Component implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
