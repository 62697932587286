<section id="portfolio" class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Latest Work</span>
            <h2>Our <b>Portfolio</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".seo">SEO</button>
            <button class="filter" data-filter=".branding">Branding</button>
            <button class="filter" data-filter=".print">Print</button>
            <button class="filter" data-filter=".video">Video</button>
        </div>
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix design">
                    <div class="single-work">
                        <a href="assets/img/work-img1.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img1.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Elegant Mockup</h3>
                                <span>UI/UX</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix print seo">
                    <div class="single-work">
                        <a href="assets/img/work-img2.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img2.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Internet Concept</h3>
                                <span>Psd</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix video branding">
                    <div class="single-work">
                        <a href="assets/img/work-img3.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img3.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Mupi Mockup</h3>
                                <span>Pencil</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix design print">
                    <div class="single-work">
                        <a href="assets/img/work-img4.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img4.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Premium Template</h3>
                                <span>Art</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix branding video">
                    <div class="single-work">
                        <a href="assets/img/work-img5.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img5.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Pencil Art</h3>
                                <span>Business</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/work-img6.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/work-img6.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Front View Psd</h3>
                                <span>Design</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>