import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../../services/SharedService.service';

@Component({
    selector: 'app-blog-HB-1105',
    templateUrl: './blog-HB-1105.component.html',
    styleUrls: ['./blog-HB-1105.component.scss']
})
export class BlogHB1105Component implements OnInit, OnDestroy {

    constructor(private sharedService: SharedService) { }

    ngOnInit() {
        this.sharedService.setVisibleMenu(false);
    }

    ngOnDestroy() {
        this.sharedService.setVisibleMenu(true);
    }

}
