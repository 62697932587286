<div class="main-banner creative-banner item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content text-center">
                    <span>We Are Creative</span>
                    <h1>The Best <b>Digital</b> Experience</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="btn-box">
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <span>or</span>
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="creative-shape">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 238" enable-background="new 0 0 1920 238" xml:space="preserve">
            <path fill="#ffffff" d="M0,0c0,0,888.955,518.735,1920,0c-0.5,149.535,0,238,0,238H0V0z"/>
        </svg>
    </div>
</div>

<app-about></app-about>

<app-features></app-features>

<app-cta></app-cta>

<app-services></app-services>

<app-who-we-are></app-who-we-are>

<app-skills></app-skills>

<app-portfolio></app-portfolio>

<app-why-we-different></app-why-we-different>

<app-funfacts-two></app-funfacts-two>

<app-team></app-team>

<app-story></app-story>

<app-pricing></app-pricing>

<app-testimonial></app-testimonial>

<app-call-to-action></app-call-to-action>

<app-faq></app-faq>

<app-partner></app-partner>

<app-blog></app-blog>

<app-subscribe></app-subscribe>

<app-contact></app-contact>