import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';
import { from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    formGroup: FormGroup;
    mensaje = {};
    constructor(private fb: FormBuilder, private translate: TranslateService) { }




    ngOnInit() {

        this.loadForm();
    }

    async sendEmail() {
        this.prepareEmail();

        emailjs.init('SwqDviXJBoUlJzkHD');

        emailjs.send('service_pzd32qu', 'template_ehzkwc3', this.mensaje).then(
            (response) => {
                alert(this.translate.instant('CONTACT.MSG'));
                this.loadForm();
            },
            (error) => {
                console.log('FAILED...', error);
            },
        );
    }


    private prepareEmail() {
        const formData = this.formGroup.value;

        this.mensaje =
        {
            to_name: "DANIEL ORTIZ - LAW OFFICES",
            from_name: formData.full_name,
            message: formData.message,
            email: formData.email,
            phone: formData.phone,
        }
    }


    loadForm() {

        this.formGroup = this.fb.group({

            full_name: ['', Validators.compose([Validators.required, Validators.maxLength(250)])],
            email: ['', Validators.compose([Validators.required, Validators.maxLength(250), Validators.email])],
            phone: ['', Validators.compose([Validators.required, Validators.maxLength(250)])],
            message: ['', Validators.compose([Validators.required, Validators.maxLength(2000), Validators.minLength(10)])],
            acepto: [false, Validators.compose([Validators.requiredTrue])],

        });
    }


    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation, controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    isControlTouched(controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }



}
