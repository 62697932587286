<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>{{ 'CONTACT.TITLE-1' | translate }}</span>
            <h2>{{ 'CONTACT.TITLE-3-1' | translate }} {{ 'CONTACT.TITLE-3-2' | translate }} {{ 'CONTACT.TITLE-3-3' | translate }}</h2>
            <p>{{ 'CONTACT.TITLE-2' | translate }}</p>
        </div>
        <div class="row ">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info h-100">
                    <div class="d-table">
                        <div class="d-table-cell ">
                            <ul class="pb-3">
                                <li><i class="icofont-google-map"></i> <span>{{ 'CONTACT.ITEM-6' | translate }}</span>570 Colonial Park Dr, Unit 310, Roswell GA 30075, United States</li>
                                <li><i class="icofont-envelope"></i> <span>{{ 'CONTACT.ITEM-7' | translate }}</span><a href="mailto:info&#64;danortizlaw.com">info&#64;danortizlaw.com</a></li>
                                <li><i class="icofont-phone"></i> <span>{{ 'CONTACT.ITEM-8' | translate }}</span><a href="tel:+14043430091">+1 (404) 343-0091</a></li>
                            </ul>

                            <div class="p-4 p-xs-0">
                                <iframe width="100%"
                                        height="100%"
                                        style="border:0"
                                        loading="lazy"
                                        allowfullscreen
                                        referrerpolicy="no-referrer-when-downgrade"
                                        src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1s570+Colonial+Park+Drive,+Suite+310,+Roswell%C2%A0Ga%C2%A030075.!5e0!6i13">
                                </iframe>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 ">
                <div class="contact-form h-100">
                    <form [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="full_name" name="full_name" [class.is-invalid]="isControlInvalid('full_name')"
                                           [class.is-valid]="isControlValid('full_name')" class="form-control" placeholder="{{ 'CONTACT.ITEM-1' | translate }}">
                                    
                                    <div class="invalid-feedback" *ngIf="controlHasError('required', 'full_name')">{{ 'CONTACT.EL' | translate }} {{ 'CONTACT.ITEM-1' | translate }} {{ 'CONTACT.REQUERIDO' | translate }}</div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" name="email" [class.is-invalid]="isControlInvalid('email')"
                                           [class.is-valid]="isControlValid('email')" class="form-control" placeholder="{{ 'CONTACT.ITEM-2' | translate }}">
                                    <div class="invalid-feedback" *ngIf="controlHasError('required', 'email')">{{ 'CONTACT.EL' | translate }} {{ 'CONTACT.ITEM-2' | translate }} {{ 'CONTACT.REQUERIDO' | translate }}</div>
                                    <div class="invalid-feedback" *ngIf="controlHasError('email', 'email')">{{ 'CONTACT.EL' | translate }} {{ 'CONTACT.ITEM-2' | translate }} {{ 'CONTACT.FORMAT' | translate }}</div>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phone" name="phone" [class.is-invalid]="isControlInvalid('phone')"
                                           [class.is-valid]="isControlValid('phone')" class="form-control" placeholder="{{ 'CONTACT.ITEM-3' | translate }}">
                                    <div class="invalid-feedback" *ngIf="controlHasError('required', 'phone')">{{ 'CONTACT.EL' | translate }} {{ 'CONTACT.ITEM-3' | translate }} {{ 'CONTACT.REQUERIDO' | translate }}</div>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="message" name="message" [class.is-invalid]="isControlInvalid('message')"
                                              [class.is-valid]="isControlValid('message')" placeholder="{{ 'CONTACT.ITEM-4' | translate }}" class="form-control" cols="30" rows="5"></textarea>
                                    <div class="invalid-feedback" *ngIf="controlHasError('required', 'message')">{{ 'CONTACT.EL' | translate }} {{ 'CONTACT.MESSAGE' | translate }} {{ 'CONTACT.REQUERIDO' | translate }}</div>

                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <mat-checkbox formControlName="acepto" name="acepto" [class.is-invalid]="isControlInvalid('acepto')" [class.disabled]="isControlInvalid('acepto')"
                                                  [class.is-valid]="isControlValid('acepto')" color="primary">{{ 'CONTACT.ITEM-5' | translate }}</mat-checkbox>
                                    <div class="invalid-feedback" *ngIf="controlHasError('required', 'acepto')">{{ 'CONTACT.CHECK' | translate }}</div>

                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button (click)="sendEmail()" [disabled]="formGroup.invalid" class="btn btn-primary">{{ 'CONTACT.TITLE-1' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
