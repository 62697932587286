<section id="pricing" class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Latest and Best</span>
            <h2>Our <b>Pricing</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tab-slider-nav">
            <ul class="tab-slider-tabs">
                <li class="tab-slider-trigger active" rel="monthly">Monthly</li>
                <li class="tab-slider-trigger" rel="yearly">Yearly</li>
            </ul>
        </div>
        <div class="tab-slider-container">
            <div id="monthly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Basic
                            </div>
                            <div class="price">
                                <sup>$</sup>145.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>10 GB</b> Hosting</li>
                                <li>2 Unique Users</li>
                                <li><b>12 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>15 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Standard
                            </div>
                            <div class="price">
                                <sup>$</sup>250.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>15 GB</b> Hosting</li>
                                <li>5 Unique Users</li>
                                <li><b>15 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>20 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Premium
                            </div>
                            <div class="price">
                                <sup>$</sup>325.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>20 GB</b> Hosting</li>
                                <li>7 Unique Users</li>
                                <li><b>20 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>25 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="yearly" class="tab-slider-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Basic
                            </div>
                            <div class="price">
                                <sup>$</sup>245.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>10 GB</b> Hosting</li>
                                <li>2 Unique Users</li>
                                <li><b>12 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>15 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Standard
                            </div>
                            <div class="price">
                                <sup>$</sup>350.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>15 GB</b> Hosting</li>
                                <li>5 Unique Users</li>
                                <li><b>15 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>20 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                Premium
                            </div>
                            <div class="price">
                                <sup>$</sup>525.99
                            </div>
                            <ul class="pricing-features">
                                <li><b>20 GB</b> Hosting</li>
                                <li>7 Unique Users</li>
                                <li><b>20 GB</b> Capacity</li>
                                <li>Weekly Backups</li>
                                <li><b>25 Domain</b> Names</li>
                                <li>Support <b>24/7</b></li>
                            </ul>
                            <a href="#" class="btn btn-primary">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>