<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!--<span>Info Update!</span>-->
            <h2>{{ 'BLOG.HEADER-1' | translate }} {{ 'BLOG.HEADER-2' | translate }}</h2>
        </div>
        <div class="blog-slides owl-carousel owl-theme">



            <div class="single-blog-post link" (click)="navegar('/blog-ley-HB-1105')">
                <div class="blog-image">
                    <a><img src="assets/img/blog-HB-1105.png" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-4' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">18 May, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-4' | translate  | slice:0:60 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-4-1' | translate | slice:0:120 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>



            <div class="single-blog-post link" (click)="navegar('/blog-asilo')">
                <div class="blog-image">
                    <a><img src="assets/img/asilo.png" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-1' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">3 Mar, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-1' | translate  | slice:0:55 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-1-1' | translate | slice:0:40 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post link" (click)="navegar('/blog-parole')">
                <div class="blog-image">
                    <a><img src="assets/img/parole.png" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-2' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">3 Mar, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-2' | translate  | slice:0:60 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-2-1' | translate | slice:0:140 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post link" (click)="navegar('/blog-tps')">
                <div class="blog-image">
                    <a><img src="assets/img/tps.png" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-3' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">3 Mar, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-3' | translate  | slice:0:60 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-3-1' | translate | slice:0:120 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post link" (click)="navegar('/blog-cbp-one')">
                <div class="blog-image">
                    <a><img src="assets/img/blog-cbp-one.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-5' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">4 Jul, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-5' | translate  | slice:0:60 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-5-1' | translate | slice:0:120 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>


            <div class="single-blog-post link" (click)="navegar('/blog-matrimonio-residencia')">
                <div class="blog-image">
                    <a><img src="assets/img/blog-matrimonio.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a>{{ 'BLOG.FLAG-6' | translate}}</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date azul-claro-dan">4 Jul, 2024</span>
                    <h3><a>{{ 'BLOG.TITLE-6' | translate  | slice:0:60 }} ...</a></h3>
                    <p>{{ 'BLOG.TEXT-6-1' | translate | slice:0:120 }} ...</p>
                    <a class="read-more-btn azul-claro-dan">{{ 'BLOG.READMORE' | translate}} <i class="icofont-double-right"></i></a>
                </div>
            </div>


        </div>
    </div>
</section>
