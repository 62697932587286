<section class="page-title-area item-bg3">
    <div class="container">
        <h2>{{ 'BLOG.TITLE-2' | translate }}</h2>
    </div>
</section>

<section class="blog-details-area ps-3 py-3">
    <div class="container">
        <div class="row">

            <div class="blog-details about-content">
                <div class="post-image text-center">
                    <img src="assets/img/parole.png" alt="image">
                </div>
                <h3>{{ 'BLOG.TITLE-2' | translate }}</h3>
                <div class="blog-meta">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-clock-time"></i> Mar 17, 2023</li>
                    </ul>
                </div>
                <p>{{ 'BLOG.TEXT-2-1' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-2' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-3' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-4' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-5' | translate }}</h3>

                <ul class="pb-3">
                    <li>{{ 'BLOG.TEXT-2-6' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-7' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-8' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-9' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-10' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-11' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-12' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-13' | translate }} </li>

                </ul>


                <h3 class="py-3">{{ 'BLOG.TEXT-2-14' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-2-15' | translate }}</p>

                <p>{{ 'BLOG.TEXT-2-16' | translate }}</p>

                <ul class="pb-3">
                    <li>{{ 'BLOG.TEXT-2-17' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-18' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-19' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-20' | translate }} </li>
                    <li>{{ 'BLOG.TEXT-2-21' | translate }} </li>

                </ul>


                <h3 class="py-3">{{ 'BLOG.TEXT-2-22' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-2-23' | translate }}</p>

                <p>{{ 'BLOG.TEXT-2-24' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-25' | translate }}</h3>

                <p>{{ 'BLOG.TEXT-2-26' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-27' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-28' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-29' | translate }}</p>


                <h3 class="py-3">{{ 'BLOG.TEXT-2-30' | translate }}</h3>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-31' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-2-32' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-33' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-2-34' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-35' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-2-36' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-37' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-2-38' | translate }}</p>

                <h3 class="py-3">{{ 'BLOG.TEXT-2-39' | translate }}</h3>
                <p>{{ 'BLOG.TEXT-2-40' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-41' | translate }}</p>
                <p>{{ 'BLOG.TEXT-2-42' | translate }}</p>


                <div class="py-5 text-center">
                    <a href="https://wa.me/14707563953?text={{ 'HOME.TXTWPP' | translate }}" target="_blank" class="btn btn-primary">{{ 'HOME.BUTTON' | translate }}</a>
                </div>

            </div>
        </div>
    </div>
</section>
