<section class="story-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="story-content">
            <div class="section-title">
                <span>Our Story</span>
                <h2>We are always with you to <b>make</b> your project success</h2>
                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i></a>
            </div>
        </div>
    </div>
</section>