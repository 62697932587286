import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    current_lan: string;

    constructor() { }

    ngOnInit() {
        this.current_lan = navigator.language ? navigator.language : 'en';
        this.current_lan = this.current_lan.substring(0, 2);
        this.current_lan = localStorage.getItem('dan-lan') ? localStorage.getItem('dan-lan') : this.current_lan;
    }



}
