<section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-checked"></i>
                    <h3 class="odometer" data-count="820">00</h3>
                    <p>Successfull Projects</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-ui-user"></i>
                    <h3 class="odometer" data-count="6523">00</h3>
                    <p>Satisfied Customers</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-win-trophy"></i>
                    <h3 class="odometer" data-count="50">00</h3>
                    <p>Awards Wining</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-crown-king"></i>
                    <h3 class="odometer" data-count="20">00</h3>
                    <p>Year Of Experience</p>
                </div>
            </div>
        </div>
    </div>
</section>