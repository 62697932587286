<section id="services" class="services-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>{{ 'SERVICES.TITLE-1' | translate }}</span>
            <h2>{{ 'SERVICES.TITLE-2' | translate }} {{ 'SERVICES.TITLE-3' | translate }}</h2>
        </div>
        <div class="row link">

            <video width="100%" height="100%"
                   controls poster="assets/img/video-preview-1.png"
                   src="{{ 'SERVICES.VIDEO' | translate }}"
                   type="video/mp4">
            </video>


            <!--<div class="story-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="story-content">
                <div class="section-title">
                    <P class="sub-title"> {{ 'SERVICES.SUB-TITLE-1' | translate }}</P>
                    <P class="sub-title"> {{ 'SERVICES.SUB-TITLE-2' | translate }}</P>
                    <a href="assets/video/services.mp4" target="_blank" class="video-btn popup-youtube"><i class="icofont-ui-play"></i></a>
                </div>
            </div>
        </div>
    </div>-->

        </div>

        <div class="row pt-5">

            <div class="tab">
                <ul class="tabs">
                    <li>
                        <a href="#">
                            {{ 'SERVICES.TITLE-SERVICE-1' | translate }}
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            {{ 'SERVICES.TITLE-SERVICE-2' | translate }}
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            {{ 'SERVICES.TITLE-SERVICE-3' | translate }}
                        </a>
                    </li>
                </ul>
                <div class="tab_content">
                    <div class="tabs_item">
                        <div class="row align-items-center">

                            <div class="col-md-12 text-center pb-5">
                                <div class="tabs_item_img">
                                    <img src="assets/img/Inmigration.png" alt="img">
                                </div>


                                <!--<div class="faq-accordion accordion pt-5" id="faqAccordion">


                                </div>-->


                            </div>

                            <div class="col-md-12 why-we-different-content">
                                <div class="tabs_item_content">

                                    <div class="faq-accordion accordion" id="faqAccordion">


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-1' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-1' | translate }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-11' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapse11" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-11' | translate }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-2' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-2' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-3' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-3' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-4' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-4' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-5' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-5' | translate }}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-6' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-6' | translate }}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-7' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-7' | translate }}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-8' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapse8" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-8' | translate }}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-9' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapse9" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-9' | translate }}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                                    {{ 'SERVICES.SUB-TITLE-SERVICE-10' | translate }} <i class="icofont-dotted-down"></i>
                                                </button>
                                            </div>
                                            <div id="collapse10" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                                <div class="accordion-body">
                                                    {{ 'SERVICES.TEXT-SERVICE-10' | translate }}
                                                </div>
                                            </div>
                                        </div>




                                    </div>



                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tabs_item">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12 why-we-different-img">
                                <div class="tabs_item_img">
                                    <img src="assets/img/penal.png" alt="img">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 why-we-different-content">
                                <div class="tabs_item_content">
                                    <h3>{{ 'SERVICES.TITLE-SERVICE-2' | translate }}</h3>
                                    <p>{{ 'SERVICES.TEXT-SERVICE-2-1' | translate }}</p>
                                    <ul style="column-count: 1">
                                        <li>{{ 'SERVICES.TEXT-SERVICE-2-ITEM-1' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-2-ITEM-2' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-2-ITEM-3' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-2-ITEM-4' | translate }}</li>
                                    </ul>
                                    <p>{{ 'SERVICES.TEXT-SERVICE-2-2' | translate }}</p>
                                    <br />
                                    <p>{{ 'SERVICES.TEXT-SERVICE-2-3' | translate }}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabs_item">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12 why-we-different-content">
                                <div class="tabs_item_content">
                                    <h3>{{ 'SERVICES.TITLE-SERVICE-3' | translate }}</h3>
                                    <p>{{ 'SERVICES.TEXT-SERVICE-3-1' | translate }}</p>
                                    <ul>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-3-ITEM-1' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-3-ITEM-2' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-3-ITEM-3' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-3-ITEM-4' | translate }}</li>
                                        <li>{{ 'SERVICES.TEXT-SERVICE-3-ITEM-5' | translate }}</li>

                                    </ul>
                                    <p>{{ 'SERVICES.TEXT-SERVICE-3-2' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 why-we-different-img">
                                <div class="tabs_item_img">
                                    <img src="assets/img/family.png" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</section>
