<section id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>{{ 'TEAM.TITLE-0' | translate }}</span>
            <h2>{{ 'TEAM.TITLE-1' | translate }} {{ 'TEAM.TITLE-2' | translate }}</h2>
            <p>{{ 'TEAM.SUB-TITLE' | translate }}</p>
        </div>

        <div class="row">

            <div class="col-lg-3 col-md-3">
                <img src="assets/img/team1.png" alt="img">

                <div class="team-content">
                    <div class="text-center p-2">
                        <h3>{{ 'TEAM.PERSON-1' | translate }}</h3>
                    </div>
                    <div title="{{ 'TEAM.TEXT-PERSON-1-2' | translate }} {{ 'TEAM.TEXT-PERSON-1-2' | translate }}" class="text-left p-2 link">
                        <p>{{ 'TEAM.TEXT-PERSON-1-1' | translate }}</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <img src="assets/img/team4.png" alt="img">
                <div class="team-content">
                    <div class="text-center p-2">
                        <h3>{{ 'TEAM.PERSON-2' | translate }}</h3>
                    </div>
                    <div class="text-left p-2">
                        <p>{{ 'TEAM.TEXT-PERSON-2' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <img src="assets/img/team3.png" alt="img">
                <div class="team-content">
                    <div class="text-center p-2">
                        <h3>{{ 'TEAM.PERSON-3' | translate }}</h3>
                    </div>
                    <div class="text-left p-2">
                        <p>{{ 'TEAM.TEXT-PERSON-3' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <img src="assets/img/team2.png" alt="img">
                <div class="team-content">
                    <div class="text-center p-2">
                        <h3>{{ 'TEAM.PERSON-4' | translate }}</h3>
                    </div>
                    <div class="text-left p-2">
                        <p>{{ 'TEAM.TEXT-PERSON-4' | translate }}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
