import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../services/SharedService.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

    menuVisible: boolean;

    currentLan: string;

    constructor(private viewportScroller: ViewportScroller, private translate: TranslateService, private sharedService: SharedService) {

    }
   
    hideMenu = false;

    private unsubscribe = new Subject<void>();


    ngOnInit() {
        this.currentLan = navigator.language ? navigator.language : 'en';
        this.currentLan = this.currentLan.substring(0, 2);
        this.currentLan = localStorage.getItem('dan-lan') ? localStorage.getItem('dan-lan') : this.currentLan;


        this.sharedService.getVisibleMenu$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.menuVisible = data;
        });
    }



    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    lanChange(lan) {
        this.translate.use(lan);
        localStorage.setItem('dan-lan', lan);
        this.currentLan = lan;
    }


    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
